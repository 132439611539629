<template>
  <div id="app" ref="app">
    <navbar class="is-hidden-mobile" v-show="isDesktop && $route.name !== 'machine'" />
    <div class="rpx-content">
      <router-view />
    </div>
  </div>
</template>

<style>
/* russo-one-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/russo-one/russo-one-v14-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
.rpx-content {
  height:100%;
  width:100%;
  position: absolute;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f8f8f8;
  font-size: 16px;
}
@media screen and (max-width: 992px) {
  .filter-wrapper .field.is-grouped {
      flex-wrap: wrap;
  }
  .filter-wrapper .button {
    width: 100%;
    margin-top: .5rem;
  }
  .filter-wrapper .field.field{
    flex: 1 1 100%;
    margin-top: .5rem;
  }

  .card-header {
    flex-wrap: wrap
  }
  .card-header-icon {
    width: 100%;
  }
  .card-header-icon .button {
    width: 100%!important
  }
  .navbar{display:block}
}

</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "AppView",
  components: { Navbar },
  data(){
    return {
      isDesktop: true
    }
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo({ top: 70, behavior: "smooth" });
    },2000)
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isDesktop = false
    } else {
      this.isDesktop = true
    }
  }
};
</script>
