export default {
  playerSit: {
    GmIsOutOfServicePlayerCardNotAllowed: "GM is out of service player card not allowed",
    PlayerReconnected: "Player reconnected",
    AnotherPlayerIsPlaying: "Another player is playing",
    PlayerNotFound: "Player is not found",
    PlayerBlacklisted: "Player is blacklisted",
    PlayerPassive: "Player is passive",
    OpenCreditModal: "Open credit modal",
    PlayerAlreadySit: "Player already sit",
    AnotherPlayerSit: "Another player sit"
  }
};
