import axios from 'axios'
import store from '@/store'
import router from '@/router'

const ax = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		//Authorization: store.state.user.access_token ? 'Bearer ' + store.state.user.access_token : null,
		Accept: 'application/json',
		ContentType: 'application/json'
	},
	withCredentials: false
})

ax.interceptors.response.use(
	response => {
		if (store.state.user.access_token) {
			ax.defaults.headers.common['Authorization'] = `${store.state.user.token_type} ${store.state.user.access_token}`
		}
		return response
	},
	error => {
		if (error.response) {
			if (error.response.status === 401) {
				store.dispatch('logout')
				router.push({name: 'home'})
			}
			if (error.response.status === 403) {
				router.push({name: 'PageNoAccess'})
			}
			if (error.response && error.response.data) {
				return Promise.reject(error.response.data.message)
			}
		}
		return Promise.reject(error)
	}
)

export const http = ax
