import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import VModal from "vue-js-modal";
import mainOidc from "./middlewares/oidcAuth";
import {http} from "@/library/axios";
import numeral from "numeral";
import VueI18n from 'vue-i18n'
import translateEn from '@/lang/en.json'
import translateTr from '@/lang/tr.json'

import {format} from "date-fns";
import enums from "@/library/enums";

Vue.use(Buefy);
Vue.use(VModal);
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: store.state.lang || 'tr', // set locale
    messages: {
        'en': translateEn,
        'tr': translateTr
    }
})
Vue.mixin({
    data() {
        return {
            enums,
            numeral,
            buttonTypes: {
                Info: "Info",
                SelectGame: "Select Game",
                Play: "Play",
                AutoPlay: "Auto Play",
                Cashout: "Cashout",
                Line1: "Line1",
                Line2: "Line2",
                Line3: "Line3",
                Line4: "Line4",
                Line5: "Line5",
                Bet1: "Bet1",
                Bet2: "Bet2",
                Bet3: "Bet3",
                Bet4: "Bet4",
                Bet5: "Bet5",
                GambleRed: "Gamble Red",
                GambleBlack: "Gamble Black",
                TakeWin: "Take Win",
                Service: "Service",
                AttendantMode: "Attendant Mode"
            },
            connection: null,
            dateFormats: {
                normalDate: "dd.MM.yyyy",
                normalDateTime: "dd.MM.yyyy HH:mm",
                fullDate: "dd.MM.yyyy HH:mm:ss",
                dbDate: "yyyy-MM-dd",
                dbDateTime: "yyyy-MM-dd HH:mm",
                dbTime: "HH:mm",
                year: "yyyy",
                humanDate: "dd MMMM yyyy"
            },
            masks: {
                numeral: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand"
                }
            },
            moneyFlowTypes: [{id: 'Cash', value: 'Nakit'}, {id: 'Card', value: 'Kredi Kartı'}, {id: 'Eft', value: 'EFT'}],
            transactionTypes: [{id: 'Deposit', value: 'Yatırım'},{id: 'Withdrawal', value: 'Çekim'}],
            statuses: [{id: 'Pending', value: 'Beklemede'},{id: 'Approved', value: 'Onaylandı'},{id: 'Cancelled', value: 'İptal Edildi'}]
        };
    },
    async mounted() {
        // if (!this.connection) await this.startSignalR();
    },
    methods: {
        numberFormat(amount = 0, currency = null){
            if (currency)
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency }).format(amount)
            else
                return new Intl.NumberFormat('de-DE').format(amount)
        },
        modalOpen ({ view, params, height = 'auto', scrollable = true, width = '50%', clickToClose = true, cb = null }) {
            this.$modal.show(view, { ...params }, {
                height,
                scrollable,
                width,
                clickToClose
            }, {
                'before-close': () => {
                    if (cb) cb()
                }
            })
        },
        async startSignalR(hub = "dispatcherhub") {
            if (this.connection !== null) return;
            const signalR = require("@microsoft/signalr");
            const signalRMsgPack = require("@microsoft/signalr-protocol-msgpack");
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(process.env.VUE_APP_API_URL + "" + hub, {
                    accessTokenFactory: () => this.$store.state.user.access_token,
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
                .withAutomaticReconnect()
                .build();
            this.connection.onreconnecting(error => {
                console.assert(this.connection.state === signalR.HubConnectionState.Reconnecting);
                this.showMessage({type: 'is-warning', message: "Connection lost.Please waiting..."});

            });
            this.connection.onreconnected(async connectionId => {
                console.assert(this.connection.state === signalR.HubConnectionState.Connected);
                this.showMessage({type: 'is-success', message: "Player reconnected..."});
            });

        },
        async invoke(event, params) {
            if (!this.connection) return;
            try {
                await this.connection.invoke(event, params);
            } catch (err) {
                console.error(err);
            }
        },
        async sendCommand(params) {
            if (!this.connection) return;
            const {event, command, data} = params;
            try {
                await this.connection.invoke(event, command, data);
            } catch (err) {
                console.error(err);
            }
        },
        showMessage(val) {
            val = {duration: 5000, message: null, position: "is-top", type: "is-info", ...val};
            this.$buefy.toast.open({
                duration: val.duration,
                message: val.message,
                position: val.position,
                type: val.type
            });
        },
        dateFormat(date, view) {
            return format(new Date(date), view);
        }
    }
});
Vue.config.productionTip = true;
mainOidc.startup().then(ok => {
    if (ok) {
        store.dispatch("setUser", {});
        if (mainOidc.user) {
            store.dispatch("setUser", mainOidc.user);
            http.defaults.headers.common["Authorization"] = `${store.state.user.token_type} ${store.state.user.access_token}`;
        }
        new Vue({
            router,
            store,
            Buefy,
            i18n,
            render: h => h(App)
        }).$mount("#app");
    } else {
        console.log("Startup was not ok");
    }
});
