<template>
  <router-link :class="machine.isAnyoneSit ? 'disable' : ''" :to="{name: 'machine',params: {id: machine.id}}">
    <div class="card machine-card">
      <header class="card-header"
              :class="{'has-background-success': !machine.isAnyoneSit, 'has-background-grey-dark': machine.isAnyoneSit}">
        <p class="card-header-title has-text-white">{{ `#${machine.floorNo} - ${machine.model}` }}</p>
      </header>
      <div class="card-content">
        <div :class="machine.isAnyoneSit?'machine-overlay' :''"></div>
        <div class="content">
          <img src="/machine.png"/>
        </div>
      </div>
      <footer class="card-footer">
        <b-button
            type="is-dark"
            class="card-footer-item"
            :disable="machine.isAnyoneSit"
        >Play
        </b-button
        >
      </footer>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MachineComponent",
  props: {
    machine: Object,
  },
};
</script>

<style lang="stylus">
.machine-card
  user-select none

  img
    user-select none
    -webkit-user-drag none
    pointer-events: none;
    position relative
    z-index 1

  &:hover
    cursor pointer
    box-shadow 0 3px 6px rgba(0, 0, 0, .4)

    img
      scale 1.2
      transition scale 1s

.disable
  pointer-events none

.machine-overlay
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
</style>
