<template>
  <div class="home container">
    <div class="card my-3" v-for="(brand, key) in brands" :key="key">
      <header class="card-header has-background-warning-dark">
        <p class="card-header-title has-text-white">{{ brand.brand }}</p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="columns is-mobile is-flex-wrap-wrap">
            <div class="column  is-2-desktop is-3-tablet is-6-mobile" v-for="(machine, k) in brand.gms" :key="k">
              <MachineComponent :ref="machine.id" :machine="machine"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MachineComponent from "@/components/Machine.vue";

export default {
  name: "HomeView",
  beforeRouteEnter(to, from, next) {
    document.title = "RPX - Lobby";
    next();
  },
  components: {MachineComponent},
  data() {
    return {
      brands: []
    };
  },
  async mounted() {
    await this.startSignalR();

    this.connection.on("GmState", async (gmId, status) => {
      console.log("gm state", gmId, status)
      this.updateMachineStatus(gmId, status);
    });
    this.connection.on("PlayerAccountUpdated", () => this.getPlayerAccounts());
    await this.connection.start();
    await this.getPlayerAccounts();
    await this.getMachines();
    const accounts = await this.connection.invoke("GetPlayerAccounts", {userId: parseInt(this.$store.state.user.profile.sub)});
    await this.$store.dispatch("setUser", {...this.$store.state.user, accounts});
  },
  methods: {
    async getMachines() {
      this.brands = await this.connection.invoke("GetGmList", {});
    },
    findMachineComponentByGmId(gmId) {
      const machineComponents = this.$refs;
      return machineComponents[gmId][0];
    },
    updateMachineStatus(gmId, status) {
      const machineComponent = this.findMachineComponentByGmId(gmId);
      machineComponent.machine.isAnyoneSit = status;
      machineComponent.$forceUpdate();
    },
    async getPlayerAccounts() {
      const accounts = await this.connection.invoke("GetPlayerAccounts", {userId: parseInt(this.$store.state.user.profile.sub)});
      await this.$store.dispatch("setUser", {...this.$store.state.user, accounts});
      if (!this.$store.state.user.activeAccount) {
        await this.$store.dispatch("setUser", {...this.$store.state.user, activeAccount: accounts.Accounts[0]});
      }
    }
  },
  async beforeDestroy() {
    await this.connection.stop();
  }
}
</script>
<style>
.machine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
