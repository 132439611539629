import Vue from 'vue'
import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2';
import store from '@/store'
import { http } from '@/library/axios'

// note the ending '/'
// const appUrl = 'http://localhost:3001/';
const appUrl = process.env.VUE_APP_APP_URL

// SignInType could be Window or Popup
const mainOidc = createOidcAuth('main', SignInType.Window, appUrl, {
	authority: process.env.VUE_APP_AUTHAPI_URL,
	client_id: process.env.VUE_APP_CLIENT_ID,
	automaticSilentRenew: true,
	monitorSession: false,
	response_type: 'code',
	scope: 'openid roles email server_scope api_scope'
});

// handle events
mainOidc.events.addAccessTokenExpiring(function () {
	// eslint-disable-next-line no-console
	console.log('access token expiring')
})

mainOidc.events.addAccessTokenExpired(function () {
	// eslint-disable-next-line no-console
	console.log('access token expired')
	window.location.replace(appUrl)
})

mainOidc.events.addSilentRenewError(function (err) {
	// eslint-disable-next-line no-console
	console.error('silent renew error', err)
})

mainOidc.events.addUserLoaded(function (user) {
	// eslint-disable-next-line no-console
	store.dispatch('setUser', user)
	if (mainOidc.user) {
		http.defaults.headers.common['Authorization'] = `${store.state.user.token_type} ${store.state.user.access_token}`
	}
})

mainOidc.events.addUserUnloaded(function () {
	// eslint-disable-next-line no-console
	console.log('user unloaded')
})

mainOidc.events.addUserSignedOut(function () {
	// eslint-disable-next-line no-console
	console.log('user signed out')
})

mainOidc.events.addUserSessionChanged(function () {
	// eslint-disable-next-line no-console
	console.log('user session changed')
})

mainOidc.events.addUserSignedIn(function (user) {
	// eslint-disable-next-line no-console
	console.log('useruseruser', user)
})

// a little something extra
Vue.prototype.$oidc = mainOidc

export default mainOidc
