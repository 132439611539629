<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="/logo.png" height="50" />
      </b-navbar-item>
    </template>
    <!--
    <template #start>
      <b-navbar-item tag="div" @click="goLive">
        <span class="mr-1">{{ $t('lobby') }}</span>
      </b-navbar-item>
    </template>
    -->
    <template #end>
      <b-navbar-item tag="div">
        <img :src="'/img/icons/'+$store.state.lang+'.svg'" width="24" @click="setLang"/>
      </b-navbar-item>
      <b-navbar-dropdown collapsible v-if="$store.state.user.activeAccount"
                         :label="`${$store.state.user.accounts.Accounts[0].CurrencyCode} ${parseFloat($store.state.user.accounts.Accounts[0].Amount).toFixed(2)}`">
        <b-navbar-item :href="'#'" :key="key" v-for="(item, key) in $store.state.user.accounts.Accounts">
          {{ parseFloat(item.Amount).toFixed(2) }} {{item.CurrencyCode}}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown :label="$store.state.user?.profile?.name" collapsible>
        <b-navbar-item tag="router-link" :to="{ name: 'moneyFlow' }">{{ $t('depositWithdraw') }}</b-navbar-item>
        <!-- <b-navbar-item tag="router-link" :to="{ name: 'slotSessions' }">Slot Sessions</b-navbar-item> -->
        <b-navbar-item tag="router-link" :to="{ name: 'transactions' }">{{ $t('gameHistory') }}</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'account' }">{{ $t('account') }}</b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item tag="router-link" :to="{ name: 'logout' }">
          <span class="mr-1">{{ $t('logout') }}</span>
          <b-icon icon="logout" custom-size="default" />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>

export default {
  name: "NavbarComponent",
  methods: {
    goLive(){
      if (this.$route.name === 'live')
        window.location.reload()
      else
        this.$router.push({name: 'live'})
    },
    setLang(){
      const lang = this.$store.state.lang === 'tr' ? 'en' : 'tr'
      this.$i18n.locale = lang
      this.$store.dispatch('setLang', lang)
    }
  }
};
</script>
