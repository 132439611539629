import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ReligaView from "../views/ReligaView.vue";
import mainOidc from '../middlewares/oidcAuth'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: 'live',
    component: () => import(/* webpackChunkName: "layout" */ '../views/Layout.vue'),
    children: [
      {
        path: "home",
        name: "home",
        component: HomeView,
        meta: {authName: mainOidc.authName}
      },
      {
        path: "live",
        name: "live",
        component: ReligaView,
        meta: {authName: mainOidc.authName}
      },
      {
        path: "profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/Profile/Index.vue"),
        meta: {authName: mainOidc.authName},
        children: [
          {
            path: "account",
            name: "account",
            component: () => import(/* webpackChunkName: "profile" */ "../views/Profile/Account.vue"),
          },
          {
            path: "transactions",
            name: "transactions",
            component: () => import(/* webpackChunkName: "profile" */ "../views/Profile/Transactions.vue"),
          },
          {
            path: "slot-sessions",
            name: "slotSessions",
            component: () => import(/* webpackChunkName: "profile" */ "../views/Profile/SlotSessions.vue"),
          },
          {
            path: "money-flow",
            name: "moneyFlow",
            component: () => import(/* webpackChunkName: "profile" */ "../views/Profile/MoneyFlow.vue"),
          }
        ]
      },
      {
        path: "machine/:id",
        name: "machine",
        component: () => import(/* webpackChunkName: "machine" */ "../views/MachineView.vue"),
        meta: {authName: mainOidc.authName}
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: {
      logoutFunction: mainOidc.signOut
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
mainOidc.useRouter(router);
export default router;
