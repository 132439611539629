import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    loggedIn: false,
    user: {},
    loading: [],
    lang: 'tr'
  },
  getters: {
    getActiveAccount(state){
      return state.user.activeAccount
    }
  },
  mutations: {
    loggedIn(state, payload) {
      state.loggedIn = payload
    },
    user(state, payload) {
      state.user = payload
    },
    lang(state, payload) {
      state.lang = payload
    },
  },
  actions: {
    setLoggedIn(context, payload) {
      context.commit('loggedIn', payload)
    },
    setUser(context, payload) {
      context.commit('user', payload)
    },
    setLoading(context, payload) {
      context.commit('loading', payload)
    },
    logout(context) {
      context.commit('loggedIn', false)
      context.commit('user', {})
    },
    setLang(context, payload) {
      context.commit('lang', payload)
    },
  },
  modules: {},
});
