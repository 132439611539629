<template>
  <iframe :src="getIframeUrl" id="iframe-view" class="iframe-view" ref="rlgIframe" allow="autoplay;fullscreen"></iframe>
</template>
<script>
import PopupMenu from "@/components/PopupMenu.vue";

export default {
  name: "LiveView",
  components: { PopupMenu },
  data() {
    return {
      lobbyInfo: null,
      dispatcherConnection: null
    };
  },
  computed: {
    getIframeUrl() {
      if (!this.lobbyInfo || !this.lobbyInfo.token || !this.lobbyInfo.userKey) return null;
      return `${this.lobbyInfo.launchUrl}?lang=en&hasLobby=false&homeUrl=close&userKey=${this.lobbyInfo.userKey}&token=${this.lobbyInfo.token}`;
    }
  },
  async mounted() {
    await this.startSignalR("religahub");
    await this.connection.start();
    await this.startSignalRDispatcher();
    await this.dispatcherConnection.start();

    const lobbyInfo = await this.connection.invoke("GetLobbyLaunchInfo");
    if (lobbyInfo && lobbyInfo.Token && lobbyInfo.UserKey && lobbyInfo.LaunchUrl) {
      this.lobbyInfo = {
        token: lobbyInfo.Token,
        userKey: lobbyInfo.UserKey,
        launchUrl: lobbyInfo.LaunchUrl
      };
    }
    await this.getPlayerAccounts();

    window.alg.redirect = val => {
      let isDesktop = true
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isDesktop = false
      } else {
        isDesktop = true
      }
      this.modalOpen({ view: PopupMenu, width: isDesktop ? "35%" : "100%" });
    };

    /*
    const rlgSection = document.getElementById("rlg");
    window.scrollTo({ top: rlgSection.offsetTop, behavior: "smooth" });

    const navbarBurger = document.querySelector(".navbar-burger");
    const navbarMenu = document.querySelector(".navbar-menu");

    navbarBurger.addEventListener("click", function() {
      setTimeout(() => {
        if (navbarMenu.classList.contains("is-active")) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, rlgSection.offsetTop);
        }
      }, 50);
    });
    let previousOrientation = window.orientation;
    const checkOrientation = function() {
      if (window.orientation !== previousOrientation) {
        previousOrientation = window.orientation;
        setTimeout(() => {
          const rlgSection = document.getElementById("rlg");
          window.scrollTo(0, rlgSection.offsetTop);
        },50)
      }
    };
    window.addEventListener("orientationchange", checkOrientation);
     */
  },
  methods: {
    async getPlayerAccounts() {
      const accounts = await this.dispatcherConnection.invoke("GetPlayerAccounts", { userId: parseInt(this.$store.state.user.profile.sub) });
      await this.$store.dispatch("setUser", { ...this.$store.state.user, accounts });
      if (!this.$store.state.user.activeAccount) {
        await this.$store.dispatch("setUser", { ...this.$store.state.user, activeAccount: accounts.Accounts[0] });
      }
    },
    async startSignalRDispatcher(hub = "dispatcherhub") {
      if (this.dispatcherConnection !== null) return;
      const signalR = require("@microsoft/signalr");
      const signalRMsgPack = require("@microsoft/signalr-protocol-msgpack");
      this.dispatcherConnection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_API_URL + "" + hub, {
          accessTokenFactory: () => this.$store.state.user.access_token,
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
        // .withAutomaticReconnect()
        .build();
    },
    async invoke(event, params) {
      if (!this.dispatcherConnection) return;
      try {
        await this.dispatcherConnection.invoke(event, params);
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style>
#iframe-view {
  width: 100%;
  height: 100%;
}
</style>
