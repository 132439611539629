<template>
  <div class="card">
    <header class="card-header has-background-black">
      <p class="card-header-title has-text-white">
        {{ $store.state.user?.profile?.name }}
        <span :key="key" v-for="(item, key) in $store.state.user.accounts.Accounts">
          <template v-if="key === 0">&nbsp;|&nbsp;</template>
          <span>{{ numberFormat(item.Amount, item.CurrencyCode) }}</span>
          <template v-if="$store.state.user.accounts.Accounts.length -1 !== key">&nbsp; ~ &nbsp;</template>
        </span>
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <aside class="menu">
          <ul class="menu-list m-0">
            <li style="list-style: none;" @click="go('moneyFlow')"><b-button expanded type="is-info">{{ $t("depositWithdraw") }}</b-button></li>
            <li style="list-style: none;" @click="go('transactions')"><b-button expanded type="is-info">{{ $t("gameHistory") }}</b-button></li>
            <li style="list-style: none;" @click="go('account')"><b-button expanded type="is-info">{{ $t("account") }}</b-button></li>
            <li style="list-style: none;" @click="$emit('close')"><b-button expanded type="is-dark">{{ $t("closeMenu") }}</b-button></li>
          </ul>
        </aside>
      </div>
    </div>
    <footer class="card-footer p-0">
      <div class="card-footer-item m-0 p-0" @click="reload">
        <b-button icon-left="home" type="is-warning" expanded>{{$t('lobby')}}</b-button>
      </div>
      <div class="card-footer-item m-0 p-0" @click="setLang">
        <img :src="'/img/icons/'+$store.state.lang+'.svg'" width="24" />
      </div>
      <div class="card-footer-item m-0 p-0" @click="go('logout')">
        <b-button icon-left="logout" type="is-danger" expanded>{{ $t('logout') }}</b-button>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",
  methods: {
    go(name) {
      this.$emit('close')
      this.$router.push({ name });
    },
    reload() {
      window.location.reload();
    },
    setLang() {
      const lang = this.$store.state.lang === "tr" ? "en" : "tr";
      this.$i18n.locale = lang;
      this.$store.dispatch("setLang", lang);
    }
  }
};
</script>

<style scoped>

</style>
